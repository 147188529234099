<template>
  <div style="">
    <span style="color: rgba(175, 181, 190);">{{ result }}</span>
  </div>
</template>
<script>
import Vue from 'vue';
import Multiselect from 'vue-multiselect';
import debounce from 'lodash.debounce';
import { getNameByEmail } from '@/utils/users';
import workflowModule from '@/store/modules/workflowModule';

export default {
  components: {

  },

  data() {
    return {
      selectedTags: [],
      wellTags: [],
      cancelBeforeStart: true,
      availableTags: [],
      highlightAllOnFocus: true,
      value: [],
      tagID: '',
      objectID: '',
      result: 'No Assignee',
      fieldName: '',
      attributeID: null,
      dataLoaded: false,
    };
  },

  async beforeMount() {
    const objectID = this.params.data.Well;
    this.objectID = objectID;
    const fieldName = this.params.colDef.colId;
    this.fieldName = fieldName;

    const data = await workflowModule.getUserAssignedToWell({ nodeId: objectID });
    //  console.log(data)
    let result = data.map((email) => getNameByEmail(email)).join(',');
    if(result.length){
      this.result = result
    }
    //  this.result
    // console.log(data);
  },

  async created() {

  },

  beforeUnmount() {

  },

  isPopup() {
    return true;
  },

  methods: {

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },

  },
};
</script>

<style lang="postcss" scoped>
.multiselect-custom >>> .multiselect__tags {
  border-color: transparent;
  max-height: 35px;
  padding: 4px;
  @apply bg-blue028;
}

.multiselect-custom >>> .multiselect__select {
  display: none;
}

.multiselect-custom >>> input {
  color: white;
  @apply bg-blue028;
}

.multiselect-custom >>> .multiselect__option--highlight:after{
  display: none;
  @apply bg-green011;
}
.multiselect-custom >>> .multiselect__content-wrapper {
  position: fixed;
  z-index: 99999 !important;
  height: 150px !important;
  top: 50px;
  width: 300px;
}

.multiselect-custom >>> .multiselect--active {
  z-index: 10;
}
</style>
